import Vue from 'vue'
import VueRouter from 'vue-router'
import { checkLogin } from "@/utils/api";

let farmId= 0;
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component:resolve => require(["@/views/component/index1"], resolve),
    meta:{farmId:farmId},
    children:[
      {
        path: '/',
        name: 'xinbeiqu-Home',
        meta:{farmId:farmId},
        component:resolve => require(["@/views/index"], resolve),
      },
      {
        path: 'industrial/:cname',
        name: 'xinbeiqu-industrial',
        meta:{backurl:'/',farmId},
        component:resolve => require(["@/views/industrial/index"], resolve),
      },
      {
        path: 'ambient',
        name: 'xinbeiqu-ambient',
        meta:{backurl:'/',farmId},
        component:resolve => require(["@/views/ambient/index"], resolve),
      },
      {
        path: 'camera',
        name: 'xinbeiqu-camera',
        meta:{farmId},
        component:resolve => require(["@/views/camera/index"], resolve),
        children:[
          {
            path: 'list',
            name: 'xinbeiqu-camera-list',
            alias: '/',
            meta:{backurl:'/',farmId},
            component:resolve => require(["@/views/camera/list"], resolve)
          }
        ]
      },
      // {
      //   path: '/*',
      //   redirect:'/'
      // },
    ]
  },
  {
    path: '/login',
    name: '登录',
    component: () => import('@/views/login/Index')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.path === '/login') {
    if (localStorage.getItem('token')) {
      next('/')
      return
    }
    next()
  } else {
    if (localStorage.getItem('token') === null) {
      next('/login')
    } else {
      const isLogin = await checkLogin()
      if (!isLogin) {
        localStorage.removeItem('token')
        next('/login')
        return
      }
      next()
    }
  }
})

export default router
