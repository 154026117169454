<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import {regionareaInfoList} from "@/utils/api";

export default {
  name:'app',
  created() {
  },
  async beforeCreate() {
    await regionareaInfoList().then(res => {
      this.$store.commit('setzhenlist', res.data)
    })
  },
  methods:{
    gotoback(){
      this.$router.push(this.backurl)
    }
  },
  computed:{
    backurl(){
      return this.$route.query?.back?this.$route.query.back:(this.$route.meta?.backurl?this.$route.meta.backurl:'')
    },
  },
}
</script>
<style lang="scss">

  @font-face {
    font-family: PangMenZhengDao;
    src: url("~@font/PangMenZhengDao.ttf");
  }
  @font-face {
    font-family: ShiShangZhongHeiJianTi;
    src: url("~@/assets/font/时尚中黑简体.TTF");
  }
  @font-face {
    font-family:DIN;
    src: url("~@font/D-DIN-Bold.ttf");
  }

  @font-face {
    font-family: SourceHanSansCN-Medium;
    src: url("~@font/SourceHanSansCN-Medium_0.otf");
  }
  @font-face {
    font-family: Source Han Sans CN;
    src: url("~@/assets/font/SourceHanSansCN-Medium_0.otf");
  }
  @function w($width) {
    @return $width;
  }

  @function h($height) {
    @return $height;
  }

  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

  }
  #app{
    font-family: SourceHanSansCN-Medium;

    width: 100%;
    height: 100%;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    //.user{
    //  background:url("https://xinbeimenghe.oss-cn-shanghai.aliyuncs.com/images/background@2x.png") no-repeat;
    //  background-size: 100% 100%;
    //}
  }
</style>
