import Vue from 'vue'
import MyDvPage from '@xdh/my/ui/lib/dv/packages/my-dv-page'
Vue.component(MyDvPage.name,MyDvPage)
import MyDvBox from '@xdh/my/ui/lib/dv/packages/my-dv-box'
Vue.component(MyDvBox.name,MyDvBox)
import MyDvList from '@xdh/my/ui/lib/dv/packages/my-dv-list'
Vue.component(MyDvList.name,MyDvList)
import MyDvRing from '@xdh/my/ui/lib/dv/packages/my-dv-ring'
Vue.component(MyDvRing.name,MyDvRing)
import MyDvPie from '@xdh/my/ui/lib/dv/packages/my-dv-pie'
Vue.component(MyDvPie.name,MyDvPie)
import MyMarquee from '@xdh/my/ui/lib/components/my-marquee'
Vue.component(MyMarquee.name,MyMarquee)
import MyDvGeo from '@xdh/my/ui/lib/dv/packages/my-dv-geo'
Vue.component(MyDvGeo.name,MyDvGeo)
import MyMapGeo from '@xdh/my/ui/lib/map/packages/my-map-geo'
Vue.component(MyMapGeo.name,MyMapGeo)

import MyMap from '@xdh/my/ui/lib/map/packages/my-map'
Vue.component(MyMap.name,MyMap)
import MyMapLayers from '@xdh/my/ui/lib/map/packages/my-map-layers'
Vue.component(MyMapLayers.name,MyMapLayers)
import MyMapImage from '@xdh/my/ui/lib/map/packages/my-map-image'
Vue.component(MyMapImage.name,MyMapImage)
import MyDialog from '@xdh/my/ui/lib/components/my-dialog'
Vue.component(MyDialog.name,MyDialog)

import MyMapHtml from '@xdh/my/ui/lib/map/packages/my-map-html'
Vue.component(MyMapHtml.name,MyMapHtml)
import MyMapCircle from '@xdh/my/ui/lib/map/packages/my-map-circle'
Vue.component(MyMapCircle.name,MyMapCircle)
import MyDate from '@xdh/my/ui/lib/components/my-date'
Vue.component(MyDate.name,MyDate)
import MyClipboard from '@xdh/my/ui/lib/components/my-clipboard'
Vue.component(MyClipboard.name,MyClipboard)
