import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import router from "@/router";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    farmId:0,
    cameraArea:[],
    choosecameraarea:'',
    choosecamerafarm:'',
    zhenlist:[],
  },
  getters:{

  },
  mutations: {
    setzhenlist(state,list=[]){
      state.zhenlist=list
    },
    setfarmId(state,value){
      state.farmId=value
    },
    setCameraArea(state,list){
      state.cameraArea=list
    },
    setchoosecamerafarm(state,name=''){
      state.choosecamerafarm=name
    },
    setchoosecameraarea(state,name=''){
      state.choosecameraarea=name
    }
  },
  actions: {
  },
  modules: {
  }
})
