import req from './request.js'
import store from '@/store'
export const getCameraInfo=(username,password)=>{
    return req.get('/xinbei/getCameraInfoByFarmIdWithZY')
}
export const getCameraInfoSF=(quantity)=>{
    return req.get('/xinbei/getCameraInfoByFarmIdWithSF',{quantity})
}
export const nuoyaweather = () => {
  return req.get('xinbei/weather')
}
export const allMonitorList = () => {
    return req.get('/gongsidaping/gk/allMonitorList')
}
export const getMenuByFarmId = () => {
    console.log(store.state.farmId)
    return req.get('/regions/getMenuByFarmId/'+store.state.farmId)
}
export const cameraInfo = () => {
    return req.get('/region/cameraInfo')
}
export const etHkCameraListByPondId = (id) => {
    return req.get('/HkCamera/getHkCameraListByPondId/'+id)
}

export const industryMainBody = (id) => {
    return req.get('/region/industryMainBody?areaId='+id)
}
export const regionareaInfoList = () => {
    return req.get('/region/areaInfoList')
}

export const getHkCameraToken = () => {
    return req.get('/HkCamera/getHkCameraToken')
}
export const getHkCameraAddress = (id) => {
    return req.get('/HkCamera/getHkCameraAddress/'+id)
}
export const getFarmTopicByFarmId = () => {
    return req.get('/xinbei/getFarmTopicByFarmId')
}
export const upload = (formdata) => {
    return req.post('/upload?cameraId='+formdata.get('cameraId'),formdata, {
        // 表示上传的是文件,而不是普通的表单数据
        'Content-Type': 'multipart/form-data'
    })
}
export const bigData=()=>{
    return req.get('/region/bigData')
}
export const dataSheet=(id,type)=>{
    return req.get('/region/dataSheet',{id,type})
}
export const screen = () => {
    return req.get('/region/screen')
}
export const regionchart = () => {
    return req.get('/region/chart')
}
export const regioncountMessage = (areaId=0) => {
    return req.get('/region/countMessage',{areaId})
}
export const regionchart2 = () => {
    return req.get('/region/chart2')
}
export const regionvillage = (type,areaId) => {
    return req.get('/region/village',{type,areaId:areaId})
}
export const regionvillageAll = (type) => {
    return req.get('/region/villageAll',{type})
}


export const regioneviceMonitoring = (type) => {
    return req.get('/region/deviceMonitoring',{type})
}
export const tailInformation = () => {
    return req.get('/region/tailInformation')
}

/**
 * 校验登录态
 * 如果非登录态,则返回false,否则返回true
 */
export const checkLogin =  async () => {
    const res = await req.post('/login/checkLogin', {
        token: localStorage.getItem('token')
    })
    if (res.status == 200) {
        const data = res.data
        if (data.code != 200 || data.data.plat != 3) {
            return false
        }
        return true
    } else {
        return false
    }
}

