import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './style.css'
import 'animate.css';
import '@/assets/css/style.scss';
import './utils'
// import './utils/rem'
Vue.use(ElementUI);
Vue.config.productionTip = true
import notifyMessage from '@/utils/notifyMessage/index'
Vue.use(notifyMessage.register)

Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

// import 'amfe-flexible'

// import Vant from 'vant'
// import 'vant/lib/index.css'
// Vue.use(Vant)





new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
